import React from 'react'

const REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function uuidValidate(uuid) {
  return typeof uuid === 'string' && REGEX.test(uuid)
}

export const hideOrShowProperty = (discover, property, type) => {
  if (discover && Object.keys(discover).length > 0) {
    if (!discover.metadata.visibility[type][property]) {
      if (
        discover.metadata.visibility[type][property] === undefined ||
        discover.metadata.visibility[type][property] === null
      ) {
        return true
      }
    }
    return !!discover.metadata.visibility[type][property]
  } else {
    return true
  }
}

function formatIngredient(value, index, options) {
  const { t, type, mapIngredientCode, allergensSet, showCode } = options

  const handleTranslation = (value) => (t ? t(`${type}.${value}`) : value)

  const isAllergen = allergensSet.has(value)
  const code = mapIngredientCode[value] || ''
  const translation = handleTranslation(value)
  let result = translation

  if (showCode) {
    result = code ? code : translation
    if (isAllergen) {
      result = code ? `${code} (${translation})` : translation
    }
  }

  if (isAllergen) {
    return <b key={index}>{result}</b>
  }

  return result
}
//Function to format ingredients from acidityRegulators and agentsStabilising categories
function formatFixedAlternativeIngredients(ingredientsTemp, product, options) {
  const { t } = options
  const fixedAlternativeIngredientsObj =
    product.ingredients?.metadata?.fixedAlternative || {}

  // Separate fixed and alternative ingredients
  const fixedIngredients = []
  const alternativeIngredients = []

  ingredientsTemp.forEach((value, index) => {
    const formattedValue = formatIngredient(value, index, options)

    if (fixedAlternativeIngredientsObj[value] === 'fixed') {
      fixedIngredients.push(formattedValue)
    } else {
      alternativeIngredients.push(formattedValue)
    }
  })

  // Format ingredients
  const formattedFixedIngredients = fixedIngredients.join(', ')
  const formattedAlternativeIngredients = alternativeIngredients.join(
    ` ${t('andOr')} `
  )

  // Combine ingredients
  let combinedArray = []
  if (formattedFixedIngredients && !formattedAlternativeIngredients) {
    combinedArray = [formattedFixedIngredients]
  } else if (!formattedFixedIngredients && formattedAlternativeIngredients) {
    combinedArray = [` ${t('contain')} `, formattedAlternativeIngredients]
  } else {
    combinedArray = [
      formattedFixedIngredients,
      `, ${t('contain')} `,
      formattedAlternativeIngredients
    ]
  }

  return combinedArray
}
export function getIngredientsTemps(
  category,
  arrayIngredientsCategory,
  arrayToFilter,
  mapIngredientCode,
  product,
  type,
  t
) {
  const allergensSet = new Set([
    'lysozyme',
    'lysozyme1',
    'potassiumBisulphite',
    'sulphurDioxide1',
    'sulphurDioxide2',
    'potassiumMetabisulphite',
    'potassiumMetabisulphite1',
    'potassiumBisulphite1',
    'sulphites',
    'sulphites1',
    'lysozyme2',
    'potassiumBisulphite2',
    'sulphurDioxide3',
    'potassiumMetabisulphite2',
    'sulphites2',
    'casein',
    'potassiumCaseinates'
  ])

  const andOrCategories = new Set([
    'acidityRegulators',
    'agentsStabilising'
    // 'aromatic_acidityRegulators',
    // 'aromatic_agentsStabilising'
  ])

  let ingredientsTemp = arrayToFilter.filter((el) =>
    arrayIngredientsCategory.includes(el)
  )
  if (!ingredientsTemp.length) {
    return []
  }

  const showCodeObj = product.ingredients?.metadata?.showCode || {}
  const showCode = showCodeObj[category] || false

  if (andOrCategories.has(category)) {
    return formatFixedAlternativeIngredients(ingredientsTemp, product, {
      t,
      type,
      mapIngredientCode,
      allergensSet,
      showCode
    })
  }

  ingredientsTemp = ingredientsTemp.map((value, index) =>
    formatIngredient(value, index, {
      t,
      type,
      mapIngredientCode,
      allergensSet,
      showCode
    })
  )

  const separator = andOrCategories.has(category) ? ` ${t('andOr')} ` : ', '

  ingredientsTemp = ingredientsTemp.reduce((prev, curr, index) => {
    return index !== ingredientsTemp.length - 1
      ? [...prev, curr, separator]
      : [...prev, curr]
  }, [])

  return ingredientsTemp
}
